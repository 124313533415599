import React, {useEffect} from 'react';
import $ from "jquery";
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_FARMERS_URL} from "../../api";
import {Link} from "react-router-dom";
import moment from "moment";
import cogoToast from "cogo-toast";

const Farmer = () => {

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        let table = $('#farmer_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Farmer Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(`${GET_FARMERS_URL}?page=${(data.length + data.start) / data.length}`, {
                    type: 'GET',
                    headers: {
                        Authorization: token
                    },
                    success: function (res) {
                        let result = [];

                        res.results.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.count,
                            recordsFiltered: res.count,
                            data: result
                        });
                    },
                    error: function(err) {
                        cogoToast.error(err.statusText, {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.username || "No username";
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.first_name ? `${data.first_name} ${data.last_name}` : "not set";
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.email || "not set";
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            true : `<span class="badge badge-success tx-white">Verified</span>`,
                            false : `<span class="badge badge-danger tx-white">Unverified</span>`
                        }[data.verified];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date_added).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        $("#farmer_table tbody").on("click", "tr", function () {
            let extract_data = table.row(this).data();

            $("#title").text("Farmer Details");
            $("#id_number").text(extract_data.raw.user.nrc_number);
            $("#first_name").text(extract_data.raw.user.first_name);
            $("#last_name").text(extract_data.raw.user.last_name);
            $("#username").text(extract_data.raw.user.username);
            $("#phone_number").text(extract_data.raw.user.phone_number);
            $("#email_address").text(extract_data.raw.user.email);
            $("#verified").text(extract_data.raw.verified ? "Yes" : "No");
            $("#active").text(extract_data.raw.user.is_active ? "Yes" : "No");
            $("#date_joined").text(moment(extract_data.raw.date_joined).format("Do MMM, YYYY"));

            $("#modalUser").modal("show");
        });

    }, [])

    const reloadTable = () => {
        $('#agent_table').DataTable().ajax.reload(null, false);
    };

    const handleShowModal = () => {

    }

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Farmers</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Farmers</h4>
                    </div>
                    {/*<div className="d-none d-md-block">*/}
                    {/*    <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add New</button>*/}
                    {/*</div>*/}
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Farmers</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="farmer_table" className="table table-hover" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Username</th>
                                        <th className="wd-25p">Full Name</th>
                                        <th className="wd-25p">Email</th>
                                        <th className="wd-10p">Status</th>
                                        <th className="wd-15p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalUser" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                                <div className="media-body">
                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2" id="title">User Detail</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">User full information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <div className="row row-sm">
                                    <div className="col-md-12">
                                        <p><strong>ID Number:</strong> <span id="id_number">Blank</span></p>
                                        <p><strong>First Name:</strong> <span id="first_name">Blank</span></p>
                                        <p><strong>Last Name:</strong> <span id="last_name">Blank</span></p>
                                        <p><strong>Username:</strong> <span id="username">Blank</span></p>
                                        <p><strong>Phone Number:</strong> <span id="phone_number">Blank</span></p>
                                        <p><strong>Email Address:</strong> <span id="email_address">Blank</span></p>
                                        <p><strong>Verified:</strong> <span id="verified">Blank</span></p>
                                        <p><strong>Active:</strong> <span id="active">Blank</span></p>
                                        <p><strong>Date Joined:</strong> <span id="date_joined">Blank</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Farmer;
