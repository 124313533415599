import React, {useEffect, useState} from 'react';
import {getGlobal} from "../../api/web";
import $ from "jquery";
import moment from "moment";
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_USERS_URL} from "../../api";
import {capitalize} from "../../utils/capitalize";
import {Link} from "react-router-dom";

const Crop = (props) => {

    const [editForm, setEditForm] = useState(false);
    const [stateReady, setStateReady] = useState(false);

    useEffect(() => {
        var table = $('#access').DataTable({
            responsive: true,
            language: {
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Crop Data</p>'
            },
            searching: false,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(`${GET_USERS_URL}`, {
                    type: 'GET',
                    data: {
                        pageNumber: (data.length + data.start) / data.length,
                        pageSize: data.length
                    },
                    success : function(res) {
                        let result = [];

                        // res.results.map((data) => {
                        //     result.push({
                        //         raw: data
                        //     });
                        // });

                        callback({
                            recordsTotal: res.count,
                            recordsFiltered: res.count,
                            data: result
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${data.first_name} ${data.last_name}`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.email;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return capitalize(data.job_title);
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.date_added).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_user"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });
    }, [])

    const handleShowModal = () => {
        setEditForm(false);
        $('#admin_form').parsley();
        $("#password").prop('required', false);
        $("#modalUser").modal("show");
    };

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const reloadTable = () => {
        $('#admin').DataTable().ajax.reload(null, false);
    };

    const handleSubmit = () => {

    }

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Crop</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Crop</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Crop</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="access" className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Quantity Seed</th>
                                        <th className="wd-20p">Expected Yield</th>
                                        <th className="wd-20p">Type of Fertilizer</th>
                                        <th className="wd-20p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalUser" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalUser", "admin_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Crop" : "Create Crop"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} crop to have information to the console</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="admin_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                        <div id="fnameWrapper" className="form-group parsley-input">
                                            <label>Crop Name<span className="tx-danger">*</span></label>
                                            <input id="first_name" type="text" className="form-control"
                                                   placeholder="Enter crop name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#fnameWrapper" required/>
                                        </div>

                                        <div id="lnameWrapper" className="form-group parsley-input">
                                            <label>Quantity Seed/Hectare<span className="tx-danger">*</span></label>
                                            <input id="last_name" type="number" className="form-control"
                                                   placeholder="Enter quantity"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#lnameWrapper" required/>
                                        </div>

                                        <div id="nrcWrapper" className="form-group parsley-input">
                                            <label>Expected Yield/Hectare<span className="tx-danger">*</span></label>
                                            <input id="nrc" type="number" className="form-control"
                                                   placeholder="Enter Yield"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#nrcWrapper" required/>
                                        </div>

                                        <div id="typeWrapper" className="form-group parsley-input">
                                            <label>Type of Fertilizers<span className="tx-danger">*</span></label>
                                            <select className="custom-select" id="title" required>
                                                <option value="" disabled selected>Select</option>
                                                <option value="mr">Mr</option>
                                                <option value="mrs">Mrs</option>
                                                <option value="dr">Dr</option>
                                                <option value="ms">Ms</option>
                                                <option value="prof">Prof</option>
                                            </select>
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ? <button className="btn btn-success btn-block">Update Crop</button> : <button className="btn btn-success btn-block">Create Crop</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Crop;
