import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'parsleyjs';
import $ from 'jquery';
import { login } from '../../api/web';

const Login = (props) => {

    document.title = "Sign In";

    const dispatch = useDispatch();

    //Selector
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [stateReady, setStateReady] = useState(false);

    useEffect(() => {
        $('#login').parsley();
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        setStateReady(true);

        const result = {
            username: $("#username").val(),
            password: $("#password").val()
        };

        dispatch(login(result)).then((response) => {
            if(response.error) setStateReady(false);
        });
    };

    if(isAuthenticated) {
        props.history.push('/dashboard');
    }

    return (
        <div>
            <div className="content content-fixed content-auth">
                <div className="container">
                    <div className="media align-items-stretch justify-content-center ht-70p pos-relative">
                        <div className="sign-wrapper">
                            <div className="wd-100p">
                                <div className="text-center">
                                    <img src={"assets/img/feip-logo.jpg"} className="ht-100 mg-b-20 img-fluid" alt="logo"/>
                                </div>
                                <h3 className="tx-color-01 text-center mg-b-10 tx-18">Company Portal</h3>
                                <p className="tx-color-03 text-center tx-16 mg-b-40">Signin to continue.</p>
                                <form id="login" className="parsley-style-1" data-parsley-validate="true" onSubmit={handleSubmit.bind()}>
                                    <div id="usernameWrapper" className="form-group parsley-input">
                                        <label>Username</label>
                                        <input id="username" type="text" className="form-control"
                                               placeholder="Enter your username"
                                               autoComplete="off"
                                               data-parsley-class-handler="#usernameWrapper" required/>
                                    </div>
                                    <div id="passwordWrapper" className="form-group parsley-input">
                                        <div className="mg-b-5"><label className="mg-b-0-f">Password</label></div>
                                        <input id="password" type="password" className="form-control"
                                               placeholder="Enter your password"
                                               data-parsley-minlength="4"
                                               data-parsley-class-handler="#passwordWrapper" required/>
                                    </div>
                                    {stateReady ? <button className="btn btn-dark btn-block" disabled><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Loading</button> : <button className="btn btn-success btn-block">Sign In</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Login;
