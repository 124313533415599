import React, {useEffect} from 'react';
import {getGlobal} from "../../api/web";
import $ from "jquery";
import 'datatables.net';
import 'datatables.net-responsive';
import {useSelector} from "react-redux";

const Dashboard = () => {

    const {user} = useSelector((state) => state.auth.user);

    useEffect(() => {
        getGlobal(user.company.pacra_id).then((response) => {
            $('#farmers').text(response.farmers.count);
            $('#agents').text(response.agents.count);
            $('#agronomists').text(response.agronomists.count);
            $('#agro_dealers').text(response.agrodealers.count);
        });
    }, [])

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Dashboard</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Agents</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="agents" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>
                            </div>
                            <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">View</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Farmers</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="farmers" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">View</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Agronomists</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="agronomists" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>
                            </div>
                            <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">View</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Agro-Dealers</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="agro_dealers" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">View</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Dashboard;
