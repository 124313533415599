import React, {useEffect} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import 'components-jqueryui';
import $ from "jquery";
import 'select2';
import {GET_COMPLAINTS_URL} from "../../api";
import {Link} from "react-router-dom";
import moment from "moment";
import cogoToast from "cogo-toast";
import 'select2';

const Complaint = () => {

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        let table = $('#complaint_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Complaint Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(`${GET_COMPLAINTS_URL}`, {
                    type: 'GET',
                    headers: {
                        Authorization: token
                    },
                    success: function (res) {
                        let result = [];

                        res.results.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.count,
                            recordsFiltered: res.count,
                            data: result
                        });
                    },
                    error: function(err) {
                        cogoToast.error(err.statusText, {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return {
                            low : `<span class="badge badge-success tx-white">Low</span>`,
                            medium : `<span class="badge badge-warning tx-white">Medium</span>`,
                            extreme : `<span class="badge badge-danger tx-white">Extreme</span>`
                        }[data.severity];
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.message ? data.message : "not set";
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.complaint_type || "not set";
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.query_status;
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date_added).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        $("#complaint_table tbody").on("click", "tr", function () {
            let extract_data = table.row(this).data();

            $("#title").text("Complaints Details");
            $("#tx_severity").text(extract_data.raw.severity);
            $("#tx_message").text(extract_data.raw.message);
            $("#tx_complaint_type").text(extract_data.raw.complaint_type);
            $("#tx_query_status").text(extract_data.raw.query_status);
            $("#tx_agronomist_note").text(extract_data.raw.agronomist_note || "Not Set");
            $("#tx_scheme").text(extract_data.raw.scheme || "Not Set");
            $("#tx_date_joined").text(moment(extract_data.raw.date_added).format("Do MMM, YYYY"));

            $("#modalComplaintInfo").modal("show");
        });

    }, [])

    const reloadTable = () => {
        $('#complaint_table').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Farmer Complaints</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Farmer Complaints</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Farmer Complaints</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="complaint_table" className="table table-hover" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Severity</th>
                                        <th className="wd-25p">Message</th>
                                        <th className="wd-25p">Complaint Type</th>
                                        <th className="wd-10p">Status</th>
                                        <th className="wd-15p">Date Created</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalComplaintInfo" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                                <div className="media-body">
                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2" id="title">Compliant Detail</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">Compliant information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <div className="row row-sm">
                                    <div className="col-md-12">
                                        <p><strong>Severity:</strong> <span id="tx_severity">Blank</span></p>
                                        <p><strong>Message:</strong> <span id="tx_message">Blank</span></p>
                                        <p><strong>Complaint Type:</strong> <span id="tx_complaint_type">Blank</span></p>
                                        <p><strong>Query status:</strong> <span id="tx_query_status">Blank</span></p>
                                        <p><strong>Agronomist Note:</strong> <span id="tx_agronomist_note">Blank</span></p>
                                        <p><strong>Scheme:</strong> <span id="tx_scheme">Blank</span></p>
                                        <p><strong>Date Joined:</strong> <span id="tx_date_joined">Blank</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Complaint;
