import axios from 'axios';
import {
    GET_AGENTS_URL,
    GET_FARMERS_URL,
    COMPANY_ID_URL,
    SIGNIN_URL,
    REGISTER_ADMIN_URL,
    COMPANY_DATA_URL,
    REGISTER_COMPANY_URL,
    REGISTER_USER_URL,
    REGISTER_SCHEME_URL,
    REGISTER_FARM_INPUT_URL,
    USER_DATA_URL,
    GET_AGRONOMISTS_URL,
    VERIFY_COMPANY_URL,
    GET_AGRODEALERS_URL,
    GET_COMPANY_USERNAME_ID_URL
} from './index';
import cogoToast from 'cogo-toast';
import {SET_AUTH_USER, UNSET_USER} from '../stores/auth';
import setAuthToken from "../utils/setAuthToken";
import errorHandler from "../utils/errorHandler";

export const login = (data) => {
    return function(dispatch) {
        //Save to localStorage
        return axios.post(SIGNIN_URL, data).then((response) => {
            console.log(response)
            return axios.get(GET_COMPANY_USERNAME_ID_URL(response.data.user.username)).then((_response) => {
                if(_response.data.job_title !== "company-rep") {
                    cogoToast.error("Your credential is not a valid company representative.", {position: 'top-right', hideAfter: 6});
                    return {
                        error: true,
                        payload: null
                    };
                }

                const token = `Token ${response.data.token}`;
                //Set token to localStorage
                localStorage.setItem('jwtToken', token);
                //Set token to Auth header
                setAuthToken(token);
                dispatch(SET_AUTH_USER({user: _response.data, token: response.data.token, isAuthenticated: true}));
            })
        }).catch((error) => {
            cogoToast.error(error.response.data.non_field_errors[0], {position: 'top-right', hideAfter: 2});
            return {
                error: true,
                payload: null
            };
        });
    }
};

export const logoutUser = () => {
    return function(dispatch) {
        //Remove toke from localStorage
        localStorage.clear();

        //Remove auth header for future requests
        setAuthToken(false);

        //Set current user to {} which will set isAuthenticated to false
        dispatch(UNSET_USER());
    }
};

export const getGlobal = (id) => {
    return axios.all([
        axios.get(GET_FARMERS_URL),
        axios.get(GET_AGENTS_URL(id)),
        axios.get(GET_AGRONOMISTS_URL(id)),
        axios.get(GET_AGRODEALERS_URL(id))
    ]).then(axios.spread((response1, response2, response3, response4) => {
        // Multiple requests are now complete
        return {
            farmers: response1.data,
            agents: response2.data,
            agronomists: response3.data,
            agrodealers: response4.data
        }
    })).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const registerAdmin = (data) => {
    return axios.post(REGISTER_ADMIN_URL, data).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const registerUser = (data) => {
    return axios.post(REGISTER_USER_URL, data).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const registerFarmInput = (data) => {
    return axios.post(REGISTER_FARM_INPUT_URL, data).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const registerScheme = (data) => {
    return axios.post(REGISTER_SCHEME_URL, data).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const actionAdmin = (method, id, data) => {
    return axios({url: USER_DATA_URL(id), method: method, data: data}).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const actionUser = (method, id, data) => {
    return axios({url: USER_DATA_URL(id), method: method, data: data}).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const registerCompany = (data) => {
    return axios.post(REGISTER_COMPANY_URL, data).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const actionCompany = (method, id, data) => {
    return axios({url: COMPANY_DATA_URL(id), method: method, data: data}).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const verifyCompany = (method, id, data) => {
    return axios({url: VERIFY_COMPANY_URL(id), method: method, data: data}).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch((err) => {
        return {
            payload: err.response.statusText,
            error: true
        }
    });
}

export const getCompanyID = (id) => {
    return axios({url: COMPANY_ID_URL(id), method: "get"}).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}
