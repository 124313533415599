import React, {useEffect, useState} from 'react';
import $ from "jquery";
import 'datatables.net';
import 'datatables.net-responsive';
import {CITY_URL, GET_AGRODEALERS_URL, PROVINCE_URL} from "../../api";
import {Link} from "react-router-dom";
import moment from "moment";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";
import {actionUser, registerUser} from "../../api/web";

let province = null;

const AgroDealer = () => {

    const {user} = useSelector((state) => state.auth.user);

    const [editForm, setEditForm] = useState(false);
    const [stateReady, setStateReady] = useState(false);
    const [editId, setEditId] = useState(null);

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        let table = $('#agro_dealer_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting AgroDealer Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(`${GET_AGRODEALERS_URL(user.company.pacra_id)}`, {
                    type: 'GET',
                    headers: {
                        Authorization: token
                    },
                    success: function (res) {
                        let result = [];

                        res.results.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.count,
                            recordsFiltered: res.count,
                            data: result
                        });
                    },
                    error: function(err) {
                        cogoToast.error(err.statusText, {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.username;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.first_name ? `${data.first_name} ${data.last_name}` : "not set";
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.email || "not set";
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            true : `<span class="badge badge-success tx-white">Verified</span>`,
                            false : `<span class="badge badge-danger tx-white">Unverified</span>`
                        }[data.verified];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date_added).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        $("#agro_dealer_table tbody").on("click", "tr", function () {
            let extract_data = table.row(this).data();

            $("#tx_id_number").text(extract_data.raw.nrc_number);
            $("#tx_first_name").text(extract_data.raw.first_name);
            $("#tx_last_name").text(extract_data.raw.last_name);
            $("#tx_username").text(extract_data.raw.username);
            $("#tx_phone_number").text(extract_data.raw.phone_number);
            $("#tx_email_address").text(extract_data.raw.email);
            $("#tx_verified").text(extract_data.raw.verified ? "Yes" : "No");
            $("#tx_active").text(extract_data.raw.is_active ? "Yes" : "No");
            $("#tx_date_joined").text(moment(extract_data.raw.date_joined).format("Do MMM, YYYY"));

            $("#modalUserInfo").modal("show");
        });

    }, [])

    const reloadTable = () => {
        $('#agro_dealer_table').DataTable().ajax.reload(null, false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setStateReady(true);

        let data = {
            username: $("#username").val(),
            first_name: $("#first_name").val(),
            last_name: $("#last_name").val(),
            password: $("#password").val(),
            email: $("#email").val(),
            phone_number: $("#mobile_number").val(),
            job_title: "agro-dealer",
            pacra_id: user.company.pacra_id,
            farmer_ussd_pin: "1234",
            nrc_number: $("#nrc_number").val(),
            company: user.company.pacra_id,
            access_level: 7,
            province: $("#state-select2").val()[0],
            town: $("#city-select2").val()[0]
        }

        if(editForm === true) {
            const removeKey = (key, {[key]: _, ...rest}) => rest;
            actionUser("patch", editId, removeKey('password', data)).then((response) => {
                setStateReady(false);
                if(response.error) return cogoToast.error("Something went wrong...", {position: "top-right"});
                onCloseModal("modalUser", "admin_form");
                $('#access').DataTable().ajax.reload();
                return cogoToast.success("User account successfully created.", {position: "top-right"});
            });

            return false;
        }

        registerUser(data).then((response) => {
            setStateReady(false);
            if(response.error) return cogoToast.error("Something went wrong...", {position: "top-right"});
            onCloseModal("modalUser", "user_form");
            $('#agro_dealer_table').DataTable().ajax.reload();
            return cogoToast.success("User account successfully created.", {position: "top-right"});
        })
    }

    const onCloseModal = (id, form) => {
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        $(`#${form}`).parsley().reset();
        $(".modal-backdrop").remove();
    };

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const handleShowModal = () => {
        setEditForm(false);
        $('#user_form').parsley();
        initializeSearch();
        $("#modalUser").modal("show");
    };

    const initializeSearch = () => {
        let city_select = $('#city-select2');
        let state_select = $('#state-select2');
        city_select.prop("disabled", true);

        state_select.select2({
            placeholder: 'Select province',
            allowClear: true,
            maximumSelectionLength: 1,
            ajax: {
                url: PROVINCE_URL,
                dataType: 'json',
                type: "GET",
                headers: {
                    "Authorization" : token,
                },
                processResults: function (data) {
                    return {
                        results: $.map(data.results, function (item) {
                            return {
                                text: item.name,
                                id: item.id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function (e) {
            province = e.params.data.text
            $('#city-select2').prop("disabled", false);
        }).on('select2:unselect', function () {
            $('#city-select2').prop("disabled", true).val(null).trigger("change");
            $("#city-select2").val(null)
        });

        city_select.select2({
            placeholder: 'Select city',
            allowClear: true,
            maximumSelectionLength: 1,
            ajax: {
                url: CITY_URL,
                dataType: 'json',
                type: "GET",
                headers: {
                    "Authorization" : token,
                },
                data: function () {
                    return {
                        province: province
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data.results, function (item) {
                            return {
                                text:item.name,
                                id: item.id
                            }
                        })
                    };
                }
            }
        })
    }

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Agro-Dealer</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Agro-Dealer</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Agro-Dealer</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="agro_dealer_table" className="table table-hover" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Username</th>
                                        <th className="wd-25p">Full Name</th>
                                        <th className="wd-25p">Email</th>
                                        <th className="wd-10p">Status</th>
                                        <th className="wd-15p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalUser" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalUser", "user_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Agro-Dealer" : "Create Agro-Dealer"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} user to have access to the console/app</p></div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="user_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                    <div id="usernameWrapper" className="form-group parsley-input">
                                        <label>Username<span className="tx-danger">*</span></label>
                                        <input id="username" type="text" className="form-control"
                                               placeholder="Enter username"
                                               autoComplete="none"
                                               data-parsley-class-handler="#usernameWrapper" required/>
                                    </div>
                                    <div id="fnameWrapper" className="form-group parsley-input">
                                        <label>First Name<span className="tx-danger">*</span></label>
                                        <input id="first_name" type="text" className="form-control"
                                               placeholder="Enter first name"
                                               autoComplete="none"
                                               data-parsley-class-handler="#fnameWrapper" required/>
                                    </div>

                                    <div id="lnameWrapper" className="form-group parsley-input">
                                        <label>Last Name<span className="tx-danger">*</span></label>
                                        <input id="last_name" type="text" className="form-control"
                                               placeholder="Enter last name"
                                               autoComplete="none"
                                               data-parsley-class-handler="#lnameWrapper" required/>
                                    </div>

                                    <div id="nrcWrapper" className="form-group parsley-input">
                                        <label>NRC Number<span className="tx-danger">*</span></label>
                                        <input id="nrc_number" type="text" className="form-control"
                                               placeholder="Enter NRC Number"
                                               autoComplete="none"
                                               data-parsley-class-handler="#nrcWrapper" required/>
                                    </div>

                                    <div id="emailWrapper" className="form-group parsley-input">
                                        <label>Email Address<span className="tx-danger">*</span></label>
                                        <input id="email" type="text" className="form-control"
                                               placeholder="Enter email address"
                                               autoComplete="none"
                                               data-parsley-class-handler="#emailWrapper" required/>
                                    </div>

                                    <div id="mobileWrapper" className="form-group parsley-input">
                                        <label>Mobile Number<span className="tx-danger">*</span></label>
                                        <input id="mobile_number" type="text" className="form-control"
                                               placeholder="Enter Mobile Number"
                                               autoComplete="none"
                                               data-parsley-class-handler="#mobileWrapper" required/>
                                    </div>

                                    <div id="passwordWrapper" className="form-group parsley-input">
                                        <label>Password<span className="tx-danger">*</span></label>
                                        <input id="password" type="password" className="form-control"
                                               placeholder="Enter new password"
                                               autoComplete="none"
                                               required
                                               data-parsley-class-handler="#passwordWrapper"/>
                                    </div>

                                    <div id="stateWrapper" className="form-group parsley-input">
                                        <label>State/Province<span className="tx-danger">*</span></label>
                                        <select className="form-control" id="state-select2" data-width="100%" multiple="multiple"
                                                data-parsley-class-handler="#stateWrapper"
                                                data-parsley-errors-container="#stateWrapper"
                                                autoComplete="off"
                                                required>
                                        </select>
                                    </div>

                                    <div id="cityWrapper" className="form-group parsley-input">
                                        <label>City<span className="tx-danger">*</span></label>
                                        <select className="form-control" id="city-select2" data-width="100%"
                                                multiple="multiple"
                                                data-parsley-class-handler="#cityWrapper"
                                                data-parsley-errors-container="#cityWrapper"
                                                autoComplete="off"
                                                required>
                                        </select>
                                    </div>

                                    {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ? <button className="btn btn-success btn-block">Update Account</button> : <button className="btn btn-success btn-block">Create Account</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalUserInfo" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                                <div className="media-body">
                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2" id="title">User Detail</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">User full information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <div className="row row-sm">
                                    <div className="col-md-12">
                                        <p><strong>ID Number:</strong> <span id="tx_id_number">Blank</span></p>
                                        <p><strong>First Name:</strong> <span id="tx_first_name">Blank</span></p>
                                        <p><strong>Last Name:</strong> <span id="tx_last_name">Blank</span></p>
                                        <p><strong>Username:</strong> <span id="tx_username">Blank</span></p>
                                        <p><strong>Phone Number:</strong> <span id="tx_phone_number">Blank</span></p>
                                        <p><strong>Email Address:</strong> <span id="tx_email_address">Blank</span></p>
                                        <p><strong>Verified:</strong> <span id="tx_verified">Blank</span></p>
                                        <p><strong>Active:</strong> <span id="tx_active">Blank</span></p>
                                        <p><strong>Date Joined:</strong> <span id="tx_date_joined">Blank</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AgroDealer;
