import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import feather from "feather-icons";
import {logoutUser} from '../../api/web';
import PerfectScrollbar from 'perfect-scrollbar';
import {useDispatch, useSelector} from "react-redux";
import {capitalize} from "../../utils/capitalize";

const Header = () => {
    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth.user);

    const [menu, set_menu] = useState({
        dashboard: "nav-item",
        agronomist: "nav-item",
        agrodealer: "nav-item",
        farmer: "nav-item",
        agent: "nav-item",
        scheme: "nav-item",
        scheme_application: "nav-item",
        complaint: "nav-item",
        farm_input: "nav-item",
        prerequisites: "nav-item"
    });

    const onLogoutClick = (event) => {
        event.preventDefault();
        dispatch(logoutUser());
    };

    //detect url change and update it.
    useEffect(() => {
        let url = window.location.hash.split("/").pop();
        Object.keys(menu).forEach(function(key){ set_menu((prevState) => ({
            ...prevState,
            [key]: "nav-item",
            [url]: "nav-item active"
        }))});
    }, [window.location.hash.split("/").pop()])

    useEffect(() => {
        $(function () {
            'use strict';
            feather.replace();

            const asideBody = new PerfectScrollbar('.aside-body', {
                suppressScrollX: true
            });

            if($('.aside-backdrop').length === 0) {
                $('body').append('<div class="aside-backdrop"></div>');
            }

            var mql = window.matchMedia('(min-width:992px) and (max-width: 1199px)');

            function doMinimize(e) {
                if (e.matches) {
                    $('.aside').addClass('minimize');
                } else {
                    $('.aside').removeClass('minimize');
                }

                asideBody.update()
            }

            mql.addListener(doMinimize);
            doMinimize(mql);

            $('.aside-menu-link').on('click', function(e){
                e.preventDefault();

                if(window.matchMedia('(min-width: 992px)').matches) {
                    $(this).closest('.aside').toggleClass('minimize');
                } else {

                    $('body').toggleClass('show-aside');
                }

                asideBody.update()
            });

            $('.nav-aside .with-sub').on('click', '.nav-link', function(e){
                e.preventDefault();

                $(this).parent().siblings().removeClass('show');
                $(this).parent().toggleClass('show');

                asideBody.update()
            });

            $('body').on('mouseenter', '.minimize .aside-body', function(e){
                console.log('e');
                $(this).parent().addClass('maximize');
            });

            $('body').on('mouseleave', '.minimize .aside-body', function(e){
                $(this).parent().removeClass('maximize');

                asideBody.update()
            });

            $('body').on('click', '.aside-backdrop', function(e){
                $('body').removeClass('show-aside');
            })
        });
    }, []);

    return (
        <aside className="aside aside-fixed">
            <div className="aside-header">
                <a href="#" className="aside-logo">Por<span>tal</span></a>
                <a href="#" className="aside-menu-link">
                    <i data-feather="menu"></i>
                    <i data-feather="x"></i>
                </a>
            </div>
            <div className="aside-body">
                <div className="aside-loggedin">
                    <div className="aside-loggedin-user">
                        <a href="#loggedinMenu" className="d-flex align-items-center justify-content-between mg-b-2"
                           data-toggle="collapse">
                            <h6 className="tx-semibold mg-b-0">{capitalize(auth.user.username)}</h6>
                            <i data-feather="chevron-down"></i>
                        </a>
                        <p className="tx-color-03 tx-12 mg-b-0">Company</p>
                    </div>
                    <div className="collapse" id="loggedinMenu">
                        <ul className="nav nav-aside mg-b-0">
                            <li className="nav-item"><Link to="#" onClick={onLogoutClick.bind()} className="nav-link"><i data-feather="log-out"></i>
                                <span>Sign Out</span></Link></li>
                        </ul>
                    </div>
                </div>

                <ul className="nav nav-aside">
                    <li className="nav-label">Menu</li>
                    <li className={menu.dashboard}><Link to="/dashboard" className="nav-link"><i data-feather="file-text"></i> <span>Dashboard</span></Link></li>
                    <li className={menu.agent}><Link to="/agent" className="nav-link"><i data-feather="at-sign"></i> <span>Agents</span></Link></li>
                    <li className={menu.farmer}><Link to="/farmer" className="nav-link"><i data-feather="anchor"></i> <span>Farmers</span></Link></li>
                    <li className={menu.agronomist}><Link to="/agronomist" className="nav-link"><i data-feather="user-plus"></i> <span>Agronomist</span></Link></li>
                    <li className={menu.agrodealer}><Link to="/agrodealer" className="nav-link"><i data-feather="user-plus"></i> <span>Agro-Dealer</span></Link></li>
                    <li className={menu.scheme}><Link to="/scheme" className="nav-link"><i data-feather="book-open"></i> <span>Schemes</span></Link></li>
                    <li className={menu.scheme_application}><Link to="/scheme_application" className="nav-link"><i data-feather="book"></i> <span>Scheme Applications</span></Link></li>
                    <li className={menu.complaint}><Link to="/complaint" className="nav-link"><i data-feather="message-square"></i> <span>Complaints</span></Link></li>
                    <li className={menu.farm_input}><Link to="/farm_input" className="nav-link"><i data-feather="folder-plus"></i> <span>Farm Input</span></Link></li>
                    <li className={menu.prerequisites}><Link to="/prerequisites" className="nav-link"><i data-feather="list"></i> <span>Prerequisites</span></Link></li>
                </ul>
            </div>
        </aside>
    );
};

export default Header;
