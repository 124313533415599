const BASE_URL = 'https://feip.app/backend/api/v1';

exports.SIGNIN_URL = `${BASE_URL}/login/`;
exports.GET_OUTGROWER_URL = `${BASE_URL}/outgrowerscheme/`;
exports.GET_OUTGROWER_SCHEME_URL = `${BASE_URL}/outgrowerscheme/`;
exports.GET_OUTGROWER_SCHEME_BY_COMPANY_ID_URL = (id) => `${BASE_URL}/get_schemes_by_company_id/?pacra_id=${id}`;
exports.GET_FARMERS_URL = `${BASE_URL}/farmer/`;
exports.GET_FARM_URL = `${BASE_URL}/farm/`;
exports.CROP_URL = `${BASE_URL}/crops/`;
exports.GET_USERS_URL = `${BASE_URL}/userobject/`;
exports.GET_FARM_INPUT_URL = `${BASE_URL}/farm_inputs`;
exports.GET_PREREQUISITES_URL = `${BASE_URL}/prerequisites`;
exports.GET_SCHEME_APPLICATION_URL = `${BASE_URL}/get_scheme_applications/`;
exports.SEARCH_SCHEME_URL = `${BASE_URL}/scheme/`;
exports.GET_USERS_ROLE_URL = (role) => `${BASE_URL}/get_user/?role=${role}`;
exports.SEARCH_FARMER_URL = (query) => `${BASE_URL}/search_users/?q=${query}`;
exports.GET_AGENTS_URL = (id) => `${BASE_URL}/get_agents_by_company_id/?pacra_id=${id}`;
exports.DT_GET_AGENTS_URL = `${BASE_URL}/get_agents_by_company_id/`;
exports.GET_AGRONOMISTS_URL = (id) => `${BASE_URL}/agronomist_by_company_id/?pacra_id=${id}`;
exports.PROVINCE_URL = `${BASE_URL}/provinces/`;
exports.CITY_URL = `${BASE_URL}/get_cities_from_province`;
exports.GET_AGRODEALERS_URL = (id) => `${BASE_URL}/agrodealer_by_company_id/?q=${id}`;
exports.GET_COMPANIES_URL = `${BASE_URL}/company/`;
exports.VERIFY_COMPANY_URL = (id) => `${BASE_URL}/verify_company/${id}`;
exports.REGISTER_ADMIN_URL = `${BASE_URL}/register/`;
exports.REGISTER_COMPANY_URL = `${BASE_URL}/company/`;
exports.REGISTER_FARM_INPUT_URL = `${BASE_URL}/farm_inputs/`;
exports.GET_COMPLAINTS_URL = `${BASE_URL}/farmer_complaints/`;
exports.REGISTER_USER_URL = `${BASE_URL}/register/`;
exports.REGISTER_SCHEME_URL = `${BASE_URL}/create_scheme/`;
exports.SEARCH_AGENT_URL = (id, query) => `${BASE_URL}/agents_in_company/?q=${query}&pacra_id=${id}`;
exports.COMPANY_SCHEME_URL = `${BASE_URL}/get_schemes_by_company_id/`;
exports.COMPANY_AGENT_URL = `${BASE_URL}/get_agents_by_company_id/`;
exports.GET_COMPANY_REP_ID_URL = (id) => `${BASE_URL}/companyrepresentative/${id}/`;
exports.GET_COMPANY_USERNAME_ID_URL = (username) => `${BASE_URL}/get_user_by_username/${username}/`;
exports.COMPANY_DATA_URL = (id) => `${BASE_URL}/company/${id}/`;
exports.USER_DATA_URL = (id) => `${BASE_URL}/userobject/${id}/`;
exports.COMPANY_ID_URL = (id) => `${BASE_URL}/company/${id}/`;
