import React, {useEffect, useState} from 'react';
import $ from "jquery";
import 'datatables.net';
import 'datatables.net-responsive';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import moment from "moment";
import cogoToast from "cogo-toast";
import {GET_FARM_INPUT_URL} from "../../api";
import {actionUser, registerFarmInput} from "../../api/web";

const FarmInput = () => {

    const [editForm, setEditForm] = useState(false);
    const [stateReady, setStateReady] = useState(false);
    const [editId, setEditId] = useState(null);

    const token = localStorage.getItem('jwtToken');

    const {user} = useSelector((state) => state.auth.user);

    useEffect(() => {
        let table = $('#scheme_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Scheme Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax(`${GET_FARM_INPUT_URL}`, {
                    type: 'GET',
                    headers: {
                        Authorization: token
                    },
                    success : function(res) {
                        let result = [];

                        res.results.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.count,
                            recordsFiltered: res.count,
                            data: result
                        });
                    },
                    error: function(err) {
                        cogoToast.error(err.statusText, {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${data.currency} ${data.price}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.unit;

                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.input_type;
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date_added).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.view_crop', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            $("#scheme_title").text(`${extract_td.raw.scheme_name} Scheme`);

            extract_td.raw.crops.map((item, index) => {
                $("#scheme_data").append(`
                    <p><h5 class="tx-primary">Crop ${index+1}</h5></p>
                    <p><strong>ID:</strong> <span>${item.id}</span></p>
                    <p><strong>Name:</strong> <span>${item.name}</span></p>
                    <p><strong>Description:</strong> <span>${item.description}</span></p>
                    <p><strong>Crop Type:</strong> <span>${item.crop_type || "Not Set"}</span></p>
                    <p><strong>Date Created:</strong> <span>${moment(item.created_at).format("Do MMM, YYYY")}</span></p>
                    <hr/>`)
            });

            $("#modalScheme").modal({ backdrop: "static", keyboard: false }, "show");
        });

        table.on('click', '.view_input', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            $("#scheme_title").text(`${extract_td.raw.scheme_name} Scheme`);

            extract_td.raw.inputs.map((item, index) => {
                $("#scheme_data").append(`
                    <p><h5 class="tx-primary">Input ${index+1}</h5></p>
                    <p><strong>ID:</strong> <span>${item.id}</span></p>
                    <p><strong>Name:</strong> <span>${item.name}</span></p>
                    <p><strong>Description:</strong> <span>${item.description}</span></p>
                    <p><strong>Input Type:</strong> <span>${item.input_type || "Not Set"}</span></p>
                    <p><strong>Metric:</strong> <span>${item.metric || "Not Set"}</span></p>
                    <p><strong>Price:</strong> <span>${item.price}</span></p>
                    <p><strong>Unit:</strong> <span>${item.unit}</span></p>
                    <p><strong>Currency:</strong> <span>${item.currency}</span></p>
                    <p><strong>Date Created:</strong> <span>${moment(item.created_at).format("Do MMM, YYYY")}</span></p>
                    <hr/>`)
            });

            $("#modalScheme").modal({ backdrop: "static", keyboard: false }, "show");
        });

        table.on('click', '.view_prerequisites', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            $("#scheme_title").text(`${extract_td.raw.scheme_name} Scheme`);

            extract_td.raw.prerequisites.map((item, index) => {
                $("#scheme_data").append(`
                    <p><h5 class="tx-primary">Prerequisites ${index+1}</h5></p>
                    <p><strong>ID:</strong> <span>${item.id}</span></p>
                    <p><strong>Type:</strong> <span>${item.type}</span></p>
                    <p><strong>Description:</strong> <span>${item.description}</span></p>
                    <p><strong>Value:</strong> <span>${item.value}</span></p>
                    <hr/>`)
            });

            $("#modalScheme").modal({ backdrop: "static", keyboard: false }, "show");
        });

    }, [])

    const reloadTable = () => {
        $('#agent_table').DataTable().ajax.reload(null, false);
    };

    const handleShowModal = () => {
        setEditForm(false);
        $('#input_form').parsley();
        $("#modalFarmInput").modal({ backdrop: "static", keyboard: false }, "show");
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const onCloseModal = (id, form) => {
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        $(`#${form}`).parsley().reset();
        $(".modal-backdrop").remove();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setStateReady(true);

        let data = {
            name: $("#name").val(),
            description: $("#description").val(),
            price: $("#price").val(),
            metric: $("#quantity").val(),
            input_type: $("#input_type").val(),
        }

        if(editForm === true) {
            const removeKey = (key, {[key]: _, ...rest}) => rest;
            actionUser("patch", editId, removeKey('password', data)).then((response) => {
                setStateReady(false);
                if(response.error) return cogoToast.error("Something went wrong...", {position: "top-right"});
                onCloseModal("modalUser", "admin_form");
                $('#access').DataTable().ajax.reload();
                return cogoToast.success("User account successfully created.", {position: "top-right"});
            });

            return false;
        }

        registerFarmInput(data).then((response) => {
            setStateReady(false);
            if(response.error) return cogoToast.error("Something went wrong...", {position: "top-right"});
            onCloseModal("modalFarmInput", "input_form");
            $('#scheme_table').DataTable().ajax.reload();
            return cogoToast.success("Farm input successfully created.", {position: "top-right"});
        })
    }

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Farm Input</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Farm Input</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Farm Input</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="scheme_table" className="table table-hover" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Price</th>
                                        <th className="wd-20p">Unit</th>
                                        <th className="wd-20p">Input Type</th>
                                        <th className="wd-20p">Date Created</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalFarmInput" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalFarmInput", "input_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Farm Input" : "Create Farm Input"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} farm input to the system</p></div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="input_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                    <div id="typeWrapper" className="form-group parsley-input">
                                        <label>Type<span className="tx-danger">*</span></label>
                                        <select className="custom-select" id="input_type" required>
                                            <option value="" disabled selected>Select</option>
                                            <option value="Seed">Seed</option>
                                            <option value="Fertilizer">Fertilizer</option>
                                            <option value="Pesticide">Pesticide</option>
                                            <option value="Equipment">Equipment</option>
                                        </select>
                                    </div>

                                    <div id="nameWrapper" className="form-group parsley-input">
                                        <label>Name<span className="tx-danger">*</span></label>
                                        <input id="name" type="text" className="form-control"
                                               placeholder="Enter name"
                                               autoComplete="none"
                                               data-parsley-class-handler="#nameWrapper" required/>
                                    </div>

                                    <div id="descriptionWrapper" className="form-group parsley-input">
                                        <label>Description<span className="tx-danger">*</span></label>
                                        <input id="description" type="text" className="form-control"
                                               placeholder="Enter description"
                                               autoComplete="none"
                                               data-parsley-class-handler="#descriptionWrapper" required/>
                                    </div>

                                    <div id="priceWrapper" className="form-group parsley-input">
                                        <label>Price<span className="tx-danger">*</span></label>
                                        <input id="price" type="number" className="form-control"
                                               placeholder="Enter amount"
                                               autoComplete="none"
                                               data-parsley-class-handler="#priceWrapper" required/>
                                    </div>

                                    <div id="quantityWrapper" className="form-group parsley-input">
                                        <label>Quantity<span className="tx-danger">*</span></label>
                                        <input id="quantity" type="number" className="form-control"
                                               placeholder="Enter quantity"
                                               autoComplete="none"
                                               data-parsley-class-handler="#quantityWrapper" required/>
                                    </div>

                                    {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ? <button className="btn btn-success btn-block">Update Input</button> : <button className="btn btn-success btn-block">Create Input</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalScheme" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                                <div className="media-body">
                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" onClick={onCloseModal.bind()}>×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2" id="scheme_title">Scheme Detail</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">Scheme information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-10 pd-sm-b-40 pd-sm-x-30">
                                <div className="row row-sm">
                                    <div id="scheme_data" className="col-md-12">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FarmInput;
