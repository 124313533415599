import React from 'react';
import PrivateRoute from '../../components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "../../components/layout/PageError";
import Dashboard from "../../pages/authorized/Dashboard";
import Crop from "../../pages/authorized/Crop";
import Agent from "../../pages/authorized/Agent";
import Farmer from "../../pages/authorized/Farmer";
import Agronomist from "../../pages/authorized/Agronomist";
import AgroDealer from "../../pages/authorized/AgroDealer";
import Scheme from "../../pages/authorized/Scheme";
import SchemeApplication from "../../pages/authorized/SchemeApplication";
import FarmInput from "../../pages/authorized/FarmInput";
import Prerequisites from "../../pages/authorized/Prerequisites";
import Complaint from "../../pages/authorized/Complaint";

const Private = () => {
    return (
        <div>
            <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/farmer" component={Farmer} />
                <PrivateRoute exact path="/agent" component={Agent} />
                <PrivateRoute exact path="/crop" component={Crop} />
                <PrivateRoute exact path="/agronomist" component={Agronomist} />
                <PrivateRoute exact path="/agrodealer" component={AgroDealer} />
                <PrivateRoute exact path="/scheme" component={Scheme} />
                <PrivateRoute exact path="/error" component={PageError} />
                <PrivateRoute exact path="/scheme_application" component={SchemeApplication} />
                <PrivateRoute exact path="/farm_input" component={FarmInput} />
                <PrivateRoute exact path="/complaint" component={Complaint} />
                <PrivateRoute exact path="/prerequisites" component={Prerequisites} />
                <Route component={PageError} />
            </Switch>
        </div>
    )
};

export default Private;
